(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/animated-circle/assets/javascripts/animated-circle.js') >= 0) return;  svs.modules.push('/components/marketplace/animated-circle/assets/javascripts/animated-circle.js');
'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const ANIMATION_DURATION_MIN = 0.2;
const ANIMATION_DURATION_MAX = 0.5;
class AnimatedCircle extends React.PureComponent {
  constructor() {
    super(...arguments);
    _defineProperty(this, "state", {
      animatonDuration: (Math.random() * (ANIMATION_DURATION_MAX - ANIMATION_DURATION_MIN) + ANIMATION_DURATION_MIN).toFixed(1)
    });
    _defineProperty(this, "_internalState", {
      hasAnimated: !this.props.animate && this.props.isVisible
    });
    _defineProperty(this, "pathRef", React.createRef());
  }
  componentDidMount() {
    if (!this._internalState.hasAnimated && this.props.isVisible) {
      this._animate();
      this._internalState.hasAnimated = true;
    }
    const length = this.pathRef.current.getTotalLength();
    this.pathRef.current.style.strokeDasharray = "".concat(length, " ").concat(length);
  }
  componentDidUpdate(_ref) {
    let {
      isVisible: prevIsVisible
    } = _ref;
    if (!this._internalState.hasAnimated && this.props.isVisible && this.props.animate) {
      this._animate();
      this._internalState.hasAnimated = true;
    } else if (prevIsVisible && !this.props.isVisible && this.props.animate) {
      this._internalState.hasAnimated = false;
    }
  }
  _animate() {
    const path = this.pathRef.current;
    const length = path.getTotalLength();
    path.style.transition = path.style.WebkitTransition = 'none';
    path.style.strokeDashoffset = length;
    path.getBoundingClientRect();
    path.style.transition = path.style.WebkitTransition = "stroke-dashoffset ".concat(this.state.animatonDuration, "s ease-in-out");
    path.style.strokeDashoffset = '0';
  }
  render() {
    const {
      isVisible
    } = this.props;
    return React.createElement("span", {
      className: "animated-circle ".concat(isVisible ? '' : ' animated-circle--is-hidden')
    }, React.createElement("svg", {
      className: "animated-circle-svg",
      viewBox: "0 0 30 30",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("defs", null, React.createElement("clipPath", {
      id: "circle-clip-path"
    }, React.createElement("path", {
      d: "M6.507,1.256 C6.057,1.411 5.675,2.013 5.909,2.634 C6.204,3.421 6.911,3.838 7.361,3.684 C13.62,1.543 18.397,1.826 21.928,4.057 C24.641,5.773 26.166,7.632 27.347,11.039 C29.764,18.009 24.061,26.337 15.347,27.23 C9.65,27.815 5.576,26.264 3.293,22.644 C1.198,19.325 1.192,15.031 3.323,11.65 C5.912,7.54 10.651,4.747 15.426,5.246 C15.9,5.296 16.12,5.154 16.169,4.678 C16.218,4.201 15.9,4.149 15.427,4.1 C10.001,3.532 4.807,6.054 1.867,10.721 C-0.621,14.67 -0.614,19.689 1.837,23.575 C4.484,27.769 9.776,30.555 16.1,29.906 C25.865,28.905 31.826,18.548 29.02,10.458 C27.721,6.712 25.936,4.543 22.844,2.588 C18.843,0.059 13.23,-1.044 6.507,1.256 z"
    }))), React.createElement("path", {
      className: "animated-circle-path",
      clipPath: "url(#circle-clip-path)",
      d: "M15.274,4.695 C9.358,4.265 5.366,7.422 2.502,11.382 C-2.304,18.024 3.057,29.738 15.27,29.237 C24.197,28.871 30.353,18.337 28.375,11.327 C26.862,5.961 20.497,-2.019 7.024,2.338",
      fillOpacity: "0",
      ref: this.pathRef,
      strokeLinecap: "round"
    })));
  }
}
setGlobal('svs.components.marketplace.animatedCircle.AnimatedCircle', AnimatedCircle);

 })(window);